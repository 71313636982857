<template>
    <div>
        <v-toolbar flat dense color="yellow">
            <v-toolbar-title style="display:flex; align-items:center;">
                <h2> {{ formTitle }}</h2>
                <v-btn class="ml-4"
                       dark
                       small
                       rounded
                       color="error">
                    <!--<galleryLoadImage :domainGuid="guid"></galleryLoadImage>-->
                </v-btn>
            </v-toolbar-title>
        </v-toolbar>

        <v-row>
            <v-col class="d-flex child-flex" cols="2" v-for="(entry, inx) in items" :key="inx">
                <div @click="handleClick(entry)">
                    <img :src="getThumbnailSrc(entry.nombre, entry.imagen)" :alt="entry.nombre" style="width: 80px;" />
                    <p>{{ entry.nombre }}</p>

                </div>
                <!--<galleryImage :guid="guid" :imageName="item"></galleryImage>-->
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import galleryImage from '@/components/Image/GalleryImage';
    import galleryLoadImage from '@/components/Image/GalleryLoadImage';

    export default {
        name: 'gallery',

        props: [
            'value',
            'items',
            'title'
        ],

        data() {
            return {
            };
        },

        computed: {
            //...mapState('image', ['items', 'item', 'defaultItem', 'index']),
            formTitle() {
                return this.title + this.value; 
            }
        },

        methods: {
            getThumbnailSrc(filename, base64Data) {
                // Inferir el tipo de imagen basándose en la extensión del nombre del archivo.
                let fileType = '';
                if (filename.endsWith('.png')) {
                    fileType = 'image/png';
                } else if (filename.endsWith('.jpg') || filename.endsWith('.jpeg')) {
                    fileType = 'image/jpeg';
                } else {
                    // Default a PNG si no se reconoce la extensión
                    fileType = 'image/png';
                }
                return `data:${fileType};base64,${base64Data}`;
            },

            handleClick(entry) {
                // Aquí puedes realizar alguna acción con el objeto entry
                this.$emit('image-selected', entry.nombre);
                console.log('Elemento clickeado:', entry);
            }
        },
        components: {
            galleryImage,
            galleryLoadImage
        }
    };
</script>
